import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"

// styles
import "../styles/bootstrap.css"
import "../styles/layout.css"

// components
import Footer from "./Footer"
import Navbar from "./Header"

const Layout = ({ children, page, footerBgVariant, currentpage }) => {
  const [pathRussian, setPathRussian] = React.useState(false)
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const getUrlPath = () => {
    if (window.location.pathname.includes("/ru")) {
      return setPathRussian(true)
    } else {
      return setPathRussian(false)
    }
  }

  React.useEffect(() => {
    /**
     * Add CookieHub tags
     */
    var cpm = {}
    ;(function (h, u, b) {
      var d = h.getElementsByTagName("script")[0],
        e = h.createElement("script")
      e.async = true
      e.src = "https://cookiehub.net/c2/31682672.js"
      e.onload = function () {
        u.cookiehub.load(b)
      }
      d.parentNode.insertBefore(e, d)
    })(document, window, cpm)
    getUrlPath()
  }, [pathRussian])

  return (
    <div className="layout" id={`main-${page}-bg`}>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500&display=swap"
          rel="stylesheet"
        ></link>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"
          integrity="sha512-1ycn6IcaQQ40/MKBW2W4Rhis/DbILU74C1vSrLJxCq57o941Ym01SwNsOMqvEBFlcgUa6xLiPY/NS5R+E6ztJQ=="
          crossorigin="anonymous"
          referrerpolicy="no-referrer"
        />
      </Helmet>

      <Navbar
        bgVariant={footerBgVariant}
        currentpage={currentpage}
        siteTitle={data.site.siteMetadata?.title || `Title`}
        pathRussian={pathRussian}
      />
      <main>{children}</main>
      <Footer bgVariant={footerBgVariant} pathRussian={pathRussian} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
