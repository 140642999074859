export const NavbarMenuLinks = [
  {
    title: "homeNav",
    route: "/",
  },
  {
    title: "faqsLabel",
    route: "/faqs",
  },
  {
    title: "contactNav",
    route: "/contact",
  },
]

