import { useI18next } from "gatsby-plugin-react-i18next"
import Airtable from "airtable"

export const getPageDirection = () => {
  return () => {
    const { language } = useI18next()
    return language === "ar" ? "rtl" : "ltr"
  }
}

export const pageDirection = getPageDirection()

export const handleInputChange = (event, setState) => {
  const { name, value } = event.target

  setState(intialState => ({ ...intialState, [name]: value }))
}

export const getAirTableClient = () => {
  return new Promise((resolve, reject)=> {
    const AIRTABLE_API_KEY = process.env.GATSBY_AIRTABLE_API_KEY
    const AIRTABLE_BASE_KEY = process.env.GATSBY_AIRTABLE_BASE
    return resolve(new Airtable({ apiKey: AIRTABLE_API_KEY }).base(
        AIRTABLE_BASE_KEY
    ));
  });
}
