import React, { useEffect, useState, useRef } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

// components
import CustomSelect from "../CustomSelect"

// images
// import Flag from "../../assets/images/dubiaflag.png"
import samarlogo from "../../assets/images/samarlogo.png"

// styles
import "./Header.css"

// utils
import { NavbarMenuLinks } from "../../helpers/routes"
import { pageDirection } from "../../helpers/utils"
import { Trans } from "gatsby-plugin-react-i18next"

const selectvalue = {
  defaultSelectText: "Please select an option",
  countryList: [
    { id: "en", name: "enLanguageOption" },
    { id: "cn", name: "znLanguageOption" },
    { id: "ar", name: "arLanguageOption" },
    { id: "ru", name: "ruLanguageOption" },
  ],
}
// Menubar Icon

 const getSocialLinks = () => {
   return [
     {
       id: 1,
       icon: <i className="fab fa-instagram" style={{ color: "#C4C4C4" }}></i>,
       alt: "samar-instagram channel",
       href: "https://instagram.com/trysamar",
     },
     {
       id: 2,
       icon: <i className="fab fa-youtube" style={{ color: "#C4C4C4" }}></i>,
       alt: "samar-youtube channel",
       href: "https://www.youtube.com/channel/UCy4KzGrFAbS7EXcm-Y0JlpQ",
     },
     {
       id: 3,
       icon: (
         <i className="fab fa-facebook-square" style={{ color: "#C4C4C4" }}></i>
       ),
       alt: "samar-facebook channel",
       href: "https://fb.com/trysamar",
     },
     {
       id: 4,
       icon: <i className="fab fa-twitter" style={{ color: "#C4C4C4" }}></i>,
       alt: "samar-twitter channel",
       href: "https://twitter.com/trysamar",
     },
   ]
 }
 const flag =
   "https://res.cloudinary.com/trysamar/image/upload/v1629227871/AE/flag_of_the_united_arab_emirates.png"
 const Navbar = ({ currentpage, bgVariant = "dark" }) => {
   const [menuBar, setMenuBar] = useState(true)
   const [valueofLanguage, setValueofLangauge] = useState(null)
   const valuesMenuref = useRef()
   const dir = pageDirection()

   const showMenuBar = () => {
     setMenuBar(prevState => {
       const state = !prevState
       return state
     })
   }

   const getArabicStyleRef = () => {
     const rtl = valuesMenuref.current.dir
     if (rtl === "rtl") {
       setValueofLangauge(true)
     } else {
       setValueofLangauge(false)
     }
     return valueofLanguage
   }

   useEffect(() => {
     getArabicStyleRef()
   }, [valueofLanguage])
   return (
     <div dir={dir} className="nav-styling" ref={valuesMenuref}>
       <div className="container-lg">
         <div className="flex-header">
           <Link className="sm-logo" to="/">
             <img
               src={samarlogo}
               alt="samar logo"
               height={2}
               lazing-loading="true"
             />
           </Link>
           <div className="flex-menu-items">
             {NavbarMenuLinks.map(navitem => (
               <Link
                 to={navitem.route}
                 key={navitem.route}
                 className="nav-link"
               >
                 <Trans>{navitem.title}</Trans>
               </Link>
             ))}

             <div className="lang-select-containner">
               <div className="d-flex align-items-center  lang-button-field">
                 <img
                   src={flag}
                   alt="united arab emirate flag"
                   className="flag-img"
                   height={16}
                   width={24}
                 />
                 <span className="launching">
                   <Trans>launchingSoonHeaderBanner</Trans>
                 </span>
               </div>
               <CustomSelect optionsList={selectvalue.countryList} />
             </div>
           </div>

           <div className="hamburger">
             <span className={`paragraph-tag-${valueofLanguage}`}>
               {currentpage}
             </span>
             <Hamburger showMenuBar={showMenuBar}/>
           </div>
         </div>
       </div>
       <MenuBar
         valueofLanguage={valueofLanguage}
         showMenuBar={showMenuBar}
         menuBar={menuBar}
         bgVariant={bgVariant}
         currentpage={currentpage}
         pathRussian
       />
     </div>
   )
 }

 export default Navbar

 Navbar.propTypes = {
   siteTitle: PropTypes.string,
 }

 Navbar.defaultProps = {
   siteTitle: ``,
 }

 export const MenuBar = ({
   showMenuBar,
   menuBar,
   currentpage,
   valueofLanguage,
   pathRussian,
 }) => {
   return (
     <div
       className={menuBar ? "menubar-background" : "menubar-backgroundactive"}
     >
       <div className="container nav-position-top">
         <div className="flex-header-menubar">
           <Link className="sm-logo" to="/">
             <img src={samarlogo} alt="samar logo" lazing-loading="true" />
           </Link>
           <span className="d-flex justify-content-center align-items-center">
             <p className={`paragraph-tag-${valueofLanguage}`}>{currentpage}</p>
             <span
               onClick={showMenuBar}
               onKeyDown={showMenuBar}
               role="button"
               tabIndex={0}
             >
               <svg
                 width="18"
                 height="18"
                 viewBox="0 0 18 18"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <path
                   fill-rule="evenodd"
                   clip-rule="evenodd"
                   d="M16.2929 0.292893C16.6835 -0.0976311 17.3166 -0.0976311 17.7072 0.292893C18.0977 0.683418 18.0977 1.31658 17.7072 1.70711L10.4142 9.00002L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L9.00002 10.4142L1.70715 17.7071C1.31663 18.0976 0.683464 18.0976 0.29294 17.7071C-0.0975847 17.3166 -0.0975857 16.6834 0.29294 16.2929L7.58581 9.00002L0.292893 1.70711C-0.097631 1.31658 -0.0976311 0.683418 0.292893 0.292893C0.683417 -0.097631 1.31658 -0.0976311 1.70711 0.292893L9.00002 7.58581L16.2929 0.292893Z"
                   fill="white"
                 />
               </svg>
             </span>
           </span>
         </div>
         <div className="menubar-flex-flag">
           <img
             src={flag}
             alt="united arab emirate flag"
             height={16}
             width={24}
           />
           <span className="launching-menubar">
             <Trans>launchingSoonHeaderBanner</Trans>
           </span>
         </div>
         <div>
           {NavbarMenuLinks.map(link => (
             <Link to={link.route} key={link.title}>
               <p
                 className={
                   pathRussian
                     ? `menubar-text-items-${pathRussian}`
                     : "menubar-text-items"
                 }
               >
                 <Trans>{link.title}</Trans>
               </p>
             </Link>
           ))}
         </div>
         <CustomSelect optionsList={selectvalue.countryList} />
         <div className="menubar-icon-container">
           {getSocialLinks().map(socialLink => (
             <Link
               target="_blank"
               to={socialLink.href}
               key={socialLink.href}
               className="social-link-icon"
             >
               {socialLink.icon}
             </Link>
           ))}
         </div>
       </div>
     </div>
   )
 }

const Hamburger = ({ showMenuBar }) => {
  return (
    <svg
      tabIndex={0}
      role="button"
      onClick={showMenuBar}
      width="23"
      height="16"
      viewBox="0 0 23 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.4 0.8C22.4 1.01217 22.3157 1.21566 22.1657 1.36569C22.0157 1.51571 21.8122 1.6 21.6 1.6H0.8C0.587827 1.6 0.384344 1.51571 0.234315 1.36569C0.0842855 1.21566 0 1.01217 0 0.8C0 0.587827 0.0842855 0.384344 0.234315 0.234315C0.384344 0.0842856 0.587827 0 0.8 0H21.6C21.8122 0 22.0157 0.0842856 22.1657 0.234315C22.3157 0.384344 22.4 0.587827 22.4 0.8ZM18.4 7.2H0.8C0.587827 7.2 0.384344 7.28429 0.234315 7.43431C0.0842855 7.58434 0 7.78783 0 8C0 8.21217 0.0842855 8.41566 0.234315 8.56569C0.384344 8.71572 0.587827 8.8 0.8 8.8H18.4C18.6122 8.8 18.8157 8.71572 18.9657 8.56569C19.1157 8.41566 19.2 8.21217 19.2 8C19.2 7.78783 19.1157 7.58434 18.9657 7.43431C18.8157 7.28429 18.6122 7.2 18.4 7.2ZM11.2 14.4H0.8C0.587827 14.4 0.384344 14.4843 0.234315 14.6343C0.0842855 14.7843 0 14.9878 0 15.2C0 15.4122 0.0842855 15.6157 0.234315 15.7657C0.384344 15.9157 0.587827 16 0.8 16H11.2C11.4122 16 11.6157 15.9157 11.7657 15.7657C11.9157 15.6157 12 15.4122 12 15.2C12 14.9878 11.9157 14.7843 11.7657 14.6343C11.6157 14.4843 11.4122 14.4 11.2 14.4Z"
        fill="white"
      />
    </svg>
  )
}
