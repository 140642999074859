import React, { useRef, useEffect, useState } from "react"
import { Link } from "gatsby"
import { pageDirection } from "../../helpers/utils"
import { Trans } from "gatsby-plugin-react-i18next"

// icons
import SamarLogo from "../Icons/SamarLogo"

// styles
import "./Footer.css"

const footerLinks = [
  {
    id: 1,
    title: "termsOfUseLabel",
    href: "/terms-of-use",
  },
  {
    id: 2,
    title: "dataPrivacyLabel",
    href: "/data-privacy",
  },
  {
    id: 3,
    title: "faqsLabel",
    href: "/faqs",
  },
]

const getSocialLinks = fillColor => {
  return [
    {
      id: 1,
      icon: "fa-instagram",
      href: "https://instagram.com/trysamar",
      title: "Samar Instagram",
    },
    {
      id: 2,
      icon: "fa-youtube",
      title: "Samar YouTube",
      href: "https://www.youtube.com/channel/UCy4KzGrFAbS7EXcm-Y0JlpQ",
    },
    {
      id: 3,
      icon: "fa-facebook-square",
      title: "Samar Facebook",
      href: "https://fb.com/trysamar",
    },
    {
      id: 4,
      icon: "fa-twitter",
      title: "Samar Twitter",
      href: "https://twitter.com/trysamar",
    },
  ]
}

const Footer = ({ bgVariant = "dark" , pathRussian}) => {
  const fillColor = bgVariant === "light" ? "#1C1D1A" : "#C4C4C4"
  const logoFillColor = bgVariant === "light" ? "#3DDC84" : "#FFFFFF"
  const dir = pageDirection()
  const footerItemRef = useRef()
  const [valueOfLanguage, setValueOfLanguage] = useState(null)
  const getArabicStyleRef = () => {
    const rtl = footerItemRef.current.dir
    if (rtl === "rtl") {
      setValueOfLanguage(true)
    } else {
      setValueOfLanguage(false)
    }
    return valueOfLanguage
  }

  useEffect(() => {
    getArabicStyleRef()
  }, [valueOfLanguage])

  return (
    <>
      <section className={`footer-container footer-container-${bgVariant}`}>
        <div className="container-lg add-padding-for">
          <div>
            <div dir={dir} className="footer-items" ref={footerItemRef}>
              <div className={`tag-line-space-${pathRussian}`}>
                <Link to="/">
                  <SamarLogo fillColor={logoFillColor} />
                </Link>
                <p className="mb-0 tagline-text">
                  <Trans>brandTagline</Trans>
                </p>
              </div>
              <div className="d-flex flex-column flex-lg-row justify-content-lg-between position-relative">
                <div className="d-flex justify-content-between footer-links">
                  {footerLinks.map(link => {
                    return (
                      <Link
                        to={link.href}
                        key={link.id}
                        className={
                          valueOfLanguage
                            ? "padding-rtl-text-item"
                            : "padding-ltr-text-item"
                        }
                      >
                        <Trans>{link.title}</Trans>
                      </Link>
                    )
                  })}
                </div>
                <div
                  className="d-flex footer-social-icons"
                  id={`pt-absolute-${valueOfLanguage}`}
                >
                  {getSocialLinks(fillColor).map(socialLink => (
                    <Link
                      target="_blank"
                      to={socialLink.href}
                      key={socialLink.id}
                      className={valueOfLanguage ? "padding-rtl" : ""}
                      title={socialLink.title}
                    >
                      <span 
                        className={`fab ${socialLink.icon} icon-md-footer`} 
                        style={{ color: `${fillColor}` }}></span>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <hr className="divider" />
          <p className="footer-main-text">
            <Trans>footerLegalDisclaimer</Trans>
          </p>
        </div>
      </section>
      <section className="footer-bottom text-white text-center">
        <section className=" container-lg ">
          <p className="footer-last-text">
            <Trans>footerCopyright</Trans>
          </p>
        </section>
      </section>
    </>
  )
}

export default Footer
