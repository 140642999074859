import React, { useState, useRef, useEffect } from "react"
import { useI18next, Trans } from "gatsby-plugin-react-i18next"
// styles
import "./CustomSelect.css"
import {logEvent} from "../../helpers/gtmUtil";

const CustomSelect = ({ optionsList }) => {
  const { changeLanguage, language } = useI18next()
  const [displayList, setDisplayList] = useState(false)
  const wrapperRef = useRef(null)
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setDisplayList(false)
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [wrapperRef])

  // This method handles the setting of name in select text area
  // and list display on selection
  const handleOptionClick = option => {
    changeLanguage(option.id)
    setDisplayList(!displayList)
    logEvent("Language Menu Selection", {
      Language_Menu_Selection: option.name,
    });
  }

  const selectedCountry =
    optionsList.filter(option => option.id === language)[0] || {}
  return (
    <div ref={wrapperRef} className="custom-select-container">
      <div
        role="button"
        tabIndex={0}
        className={displayList ? "selected-text-drop active" : "selected-text"}
        onClick={() => setDisplayList(prevState => !prevState)}
        // onKeyDown={handleOptionClick}
      >
        <Trans>{selectedCountry.name}</Trans>
      </div>
      {displayList && (
        <ul className="select-options">
          {optionsList
            .filter(v => v.id !== language)
            .map(option => {
              return (
                <li
                  className="custom-select-option"
                  data-name={option.name}
                  key={option.id}
                  onClick={() => handleOptionClick(option)}
                >
                  <Trans>{option.name}</Trans>
                </li>
              )
            })}
        </ul>
      )}
    </div>
  )
}

export default CustomSelect
